export default {
    PRODWS_URL: process.env.REACT_APP_PRODWS_AFREESPACE_URL || "https://prodws.afreespace.com",
    TIMWS_URL: process.env.REACT_APP_TIMWS_AFREESPACE_URL || "https://tim-ws.afreespace.com",
    SPOTWS_URL: process.env.REACT_APP_SPOT_AFREESPACE_URL || "https://spotws.afreespace.com",
    ELECTRIC_URL: process.env.REACT_APP_ELECTRIC_IMP_URL || "https://imp-agent-proxy.afreespace.com",
    UPLOAD_URL: process.env.REACT_APP_UPLOAD_AFREESPACE_URL || "https://tim-tool-s3.dev.afreespace.com",
    ELECTRIC_AGENT_URL: process.env.REACT_APP_ELECTRIC_IMP_AGENT_URL || "https://agent.electricimp.com",
    LOGIN_BASE_URL: process.env.REACT_APP_LOGIN_BASE_URL || "https://login.afreespace.com",
    PROD_REFERRER: process.env.REACT_APP_PROD_REFERRER_URL || "https://dot.afreespace.com",
    DEV_REFERRER: process.env.REACT_APP_DEV_REFERRER_URL || "https://dot.dev.afreespace.com",
    LOCALHOST_REFERRER: process.env.LOCALHOST_REFERRER_URL  || "https://localhost.afreespace.com:8443",
    WEB_SOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL || "wss://spot-wss.afreespace.com",
}