import Axios from "axios";
import { async } from "q";

import config from "../config";
import { type } from "@testing-library/user-event/dist/type";


const AuthService = {
	

	isKeyValid() {
		const expiryTime = window.localStorage.getItem("spaceio-key-expiry");
		const now = Date.now();
		return Number(now) <= Number(expiryTime);
	},

	async fetchAuthKey() {
		let key = AuthService.authKey;
		if (AuthService.isKeyValid()) {
			return key
		};
		const newAuthKey = await this.refreshTokenAPI(AuthService.refreshToken);
		return newAuthKey;
	},

	get refreshToken() {
		let key = window.localStorage.getItem("spaceio-refresh-token");
		if (key === null) {
			return null
		};
		return key;
	},

	set refreshToken(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-refresh-token", key);
	},

	get authKey() {
		let key = window.localStorage.getItem("spaceio-key");
		if (key === null) return null
		return key;
	},

	set authKey(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-key", key);
	},

	getAuthToken: async (authCode) => {
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode,
				},
				method: 'post',
				url: `${config.PRODWS_URL}/api/login/code?requiredRefreshToken=true`
			});
			console.log('res', res)
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			// console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},
	authenticationKey: function () {
		return localStorage.getItem("spaceio-key") ? localStorage.getItem("spaceio-key") : null
	},

	logout: () => {
		window.localStorage.removeItem("spaceio-key");
	},

	refreshTokenAPI: async (refreshKey) => {
		try {
			const res = await Axios({
				headers: {
					"x-refresh-token": refreshKey,
				},
				method: 'post',
				url: `${config.PRODWS_URL}/api/refresh-token?requiredRefreshToken=true`
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			//console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));

			return newAuthKey;
		} catch (err) {
			console.error(err);
			console.error(err);
			throw (err.response.data.error)
		}
	},


	//Based on Product Id fetches floor ID & Name if that product is present on that floor
	getFloorIdAndNameUsingProductId: async (authKey, productId) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/admin/${productId}/locations`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey()
				}
			});
			// console.log(res.data);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getProductIdAndNameUsingProductId: async (authKey) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/admin/searchProducts`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey()
				}
			});
			// console.log(res.data);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getFloorDetails: async (authKey, floorId) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey()
				}
			});
			return [res.data];
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	readBTIMStatus: async (agentId) => {
		try {
			const res = await Axios.get(`${config.ELECTRIC_URL}/${agentId}/BTIM/currentStatus`, {
				headers: {
					// "Authorization": AuthService.authenticationKey(),
					"Authorization": await AuthService.fetchAuthKey()

				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data)
		}
	},

	sendDebugStatusToBatTim: async (debugModeFlag, agentId) => {

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		let raw = JSON.stringify({
			"utcTime": 0,
			"debugModeFlag": debugModeFlag,
			"debugModeOnTime": 5,
			"maxDebugModeOnTime": 0
		});
		let url = `${config.ELECTRIC_AGENT_URL}/${agentId}/BTIM/debugMode`;
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		try {
			const response = await fetch(url, requestOptions);
			// console.log('Status code:', response.status); 
			return response.status
			// const responseText = await response.text();
			// Use the responseText as needed
		} catch (error) {
			console.log('Error:', error);
		}
	},

	sendCalibration: async (forceCalibrationFlag, agentId) => {

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		let raw = JSON.stringify({
			"utcTime": 0,
			"forceCalibrationFlag": forceCalibrationFlag
		});
		let url = `${config.ELECTRIC_AGENT_URL}/${agentId}/BTIM/forceCalibration`;
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		try {
			const response = await fetch(url, requestOptions);
			return response.status
			// const responseText = await response.text();
			// Use the responseText as needed
		} catch (error) {
			console.log('Error:', error);
		}
	},


	locationAPI: async (authKey, floorId) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/locations/${floorId}`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey(),
				}
			});

			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},


	getFloorSpaces: async (authKey, floorId, spaceId) => {
		// console.log("___________",authKey);
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/locations/${floorId}/spaces`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey()
				}
			});
			res.data.map((s) => {
				console.log("s", { sId: s.id, stoye: typeof s.id, spaceId, spaceIdType: Array.isArray(spaceId) })
			})
			let PolySpaces = res.data.filter((space) => {
				if (Array.isArray(spaceId)) {
					return spaceId.includes(space.id.toString())
				} else if (typeof spaceId === "string") {
					return spaceId = space.id.toString();
				} else if (typeof spaceId === "number") {
					return space.id === spaceId
				}
			});
			//console.log("pl inside auth.js", PolySpaces);

			if (PolySpaces.length > 0) {

				//console.log("radius", PolySpaces[0].marker?.data);
				//console.log("srf points", PolySpaces[0].srf);

				let tempMark = PolySpaces[0].marker?.data;
				//console.log("tempMark", tempMark);
				const match = tempMark.includes(";") ? tempMark.split(";")[0] : false
				if (match) {
					tempMark = parseInt(match); // Convert the matched string to an actual number
					// console.log(typeof tempMark);
				}

				const srfx = PolySpaces[0].srf.x;
				const srfy = PolySpaces[0].srf.y;

				// const regex = new RegExp('.*points="([^"]*)".*');
				const result = typeof tempMark === "string" ? tempMark.includes('polygon') : false
				//console.log("result", result);

				if (result) {
					//console.log("there is polygon")
					//console.log("Polygon Points", PolySpaces[0])
				}
				else {
					//console.log("there is no polygon");

					const locationAPIresponse = await AuthService.locationAPI(await AuthService.fetchAuthKey(), floorId);
					//console.log("locationAPIresponse", locationAPIresponse);

					let scalingFactor = locationAPIresponse[0].scalingFactor;
					//console.log("scalingFactor", locationAPIresponse[0].scalingFactor);

					//let imageHeight = locationAPIresponse[0].image.height;

					// let left = srfx - 0;
					// let top = imageHeight - srfy - 0;

					const dbdataresult = await AuthService.getTimDBSpaces(await AuthService.fetchAuthKey(), spaceId);
					//console.log(dbdataresult);
					if (dbdataresult && dbdataresult.length) {
						let height = dbdataresult[0].heightFromFloor;
						//console.log("Height : ", dbdataresult[0].heightFromFloor);

						let width = 1.534 * height; /*Area width*/
						let length = height / 1.5 * 2.856;/*Area height*/
						//console.log("normal parameters", width, length)

						let rxWidth = width * scalingFactor;
						let ryLength = length * scalingFactor;
						//console.log("parameter", rxWidth, ryLength);

						// let gridWidth = rxWidth / 24;
						// let gridLength = ryLength / 32;
						// console.log("grid parameters", gridWidth, gridLength);

						let angle = dbdataresult[0].angle * Math.PI / 180;
						//console.log("Angle : ", angle);
						let [x, y] = dbdataresult[0].coordinates.split(",");
						let coordinates = [x * scalingFactor, y * scalingFactor];
						//console.log("Coordinate : ", coordinates);

						let buffer = 0.8;
						// let c = [{ x: srfx - ryLength / buffer, y: srfy - rxWidth / buffer },
						// { x: srfx + ryLength / buffer, y: srfy - rxWidth / buffer },
						// { x: srfx + ryLength / buffer, y: srfy + rxWidth / buffer },
						// { x: srfx - ryLength / buffer, y: srfy + rxWidth / buffer }]
						// console.log("c", c);

						//top-left corner 
						let topLeftX = srfx + (-rxWidth / buffer) * Math.cos(angle) - (ryLength / buffer) * Math.sin(angle);
						let topLeftY = srfy + (-rxWidth / buffer) * Math.cos(angle) + (ryLength / buffer) * Math.sin(angle);
						let topLeft = { x: topLeftX, y: topLeftY };

						//top-right corner 
						let topRightX = srfx + (rxWidth / buffer) * Math.cos(angle) - (ryLength / buffer) * Math.sin(angle);
						let topRightY = srfy + (rxWidth / buffer) * Math.cos(angle) + (ryLength / buffer) * Math.sin(angle);
						let topRight = { x: topRightX, y: topRightY };

						//bottom-left corner 
						let bottomLeftX = srfx + (-rxWidth / buffer) * Math.cos(angle) - (-ryLength / buffer) * Math.sin(angle);
						let bottomLeftY = srfy + (-rxWidth / buffer) * Math.cos(angle) + (-ryLength / buffer) * Math.sin(angle);
						let bottomLeft = { x: bottomLeftX, y: bottomLeftY };

						//bottom-right corner
						let bottomRightX = srfx + (rxWidth / buffer) * Math.cos(angle) - (-ryLength / buffer) * Math.sin(angle);
						let bottomRightY = srfy + (rxWidth / buffer) * Math.cos(angle) + (-ryLength / buffer) * Math.sin(angle);
						let bottomRight = { x: bottomRightX, y: bottomRightY };

						let c = [topLeft, topRight, bottomRight, bottomLeft];
						//console.log("c", c);

						var xValues = c.map(item => parseFloat(item.x.toFixed(2)));
						var yValues = c.map(item => parseFloat(item.y.toFixed(2)));
						//console.log("XXXXXXXXXXX", xValues)
						//console.log("YYYYYYYYYY", yValues)

						var pointsString = xValues.map((x, index) => `${x},${yValues[index]}`).join(' ');
						//console.log(pointsString)
						PolySpaces[0]["marker"]["data"] = `<polygon points="${pointsString}"/>"`;

						//console.log("Polygon Points", PolySpaces[0])
					}
				}
			}
			return PolySpaces;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getTimDBSpaces: async (authKey, spaces) => {
		try {
			const res = await Axios.get(`${config.TIMWS_URL}/api/config/space/${spaces}`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey(),
				}
			});
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	// getTimRooms: async (authKey, floorId) => {
	// 	try {
	// 		const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}/devices`, {
	// 			headers: {
	// 				"x-auth-key": authKey,
	// 			}
	// 		});
	// 		return res.data;
	// 	} catch (err) {
	// 		console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },

	//Tim Plus Rooms
	// getTimPlusRooms: async (authKey, floorId,productId) => {
	// 	try {
	// 		const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}/devices?productId=${productId}`, {
	// 			headers: {
	// 				"X-auth-key": authKey,
	// 			}
	// 		});
	// 		//console.log("@@@@@@@@@@@@@@@",res.data)
	// 		return res.data;
	// 	} catch (err) {
	// 		//console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },
	// getTIMPDevice: async (authKey, floorId, productId) => {
	// 	try {
	// 		const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}/devices?productId=${productId}`, {
	// 			headers: {
	// 				"X-auth-key": authKey,
	// 			}
	// 		});
	// 		return res.data;
	// 	} catch (err) {
	// 		// console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },
	// getBatTimPlusRooms: async (authKey, floorId) => {
	// 	try {
	// 		const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}/devices`, {
	// 			headers: {
	// 				"X-auth-key": authKey,
	// 			}
	// 		});
	// 		return res.data;
	// 	} catch (err) {
	// 		console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },

	// getBatTimPlusRoomsNew: async (authKey, floorId, productId) => {
	// 	try {
	// 		const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}/devices?productId=${productId}`, {
	// 			headers: {
	// 				"X-auth-key": authKey,
	// 			}
	// 		});
	// 		return res.data;
	// 	} catch (err) {
	// 		console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },

	getDeviceFromFloorAndProductID: async (authKey, floorId, productId) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/admin/locations/${floorId}/devices?productId=${productId}`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey()
				}
			});
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},


	// getConfigZone: async (assetId) => {

	// 	let myHeaders = new Headers();

	// 	myHeaders.append("x-location-token", await AuthService.fetchAuthKey());
	// 	myHeaders.append("authorization", process.env.REACT_APP_AUTHORIZATION_KEY);
	// 	myHeaders.append("Content-Type", "application/json");

	// 	let raw = JSON.stringify([
	// 		assetId
	// 	]);

	// 	let requestOptions = {
	// 		method: 'POST',
	// 		headers: myHeaders,
	// 		body: raw,
	// 		redirect: 'follow'
	// 	};
	// 	const response = await fetch(`${config.SPOTWS_URL}/assets/data/8/current`, requestOptions);
	// 	const result = await response.json();
	// 	return result[0];
	// },

	getConfigZone: async (assetId) => {

        try {
            const res = await Axios.post(`${config.SPOTWS_URL}/assets/data/8/current`, assetId, {
                headers: {
                    "x-location-token": await AuthService.fetchAuthKey(),
                    "authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
                    "Content-Type": "application/json"
                },
            },
            );
            return res.data;
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }
    },

	updateFLOConfig: async (dataToWrite) => {
		try {
			const res = await Axios.post(`${config.SPOTWS_URL}/ingest/8?asyncCall=false`, dataToWrite, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}

	},

	coldReboot: async (agentIdArr) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", await AuthService.fetchAuthKey());

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.ELECTRIC_URL}/${agentIdArr}/FLO/newFloConfigData`, requestOptions)
		const result = await response.json();
		return result;
	},

	// getFloData: async (authKey, assetId) => {
	// 	try {
	// 		const res = await Axios.post(`https://spotws.afreespace.com/assets/data/8/current`, assetId, {
	// 			headers: {
	// 				"x-location-token": authKey,
	// 				"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
	// 				"Content-Type": "application/json"
	// 			},
	// 		},
	// 		);
	// 		return res.doorDimensions;
	// 	} catch (err) {
	// 		console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },

	getRawDevices: async (authKey, floorId) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/locations/${floorId}/raw-devices`, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey()
				}
			});
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getDeviceStatus: async (agentId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", await AuthService.fetchAuthKey());

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.ELECTRIC_URL}/${agentId}/FLO/deviceStatus`, requestOptions)
		const result = await response.json();
		return result;
	},

	getOccupanceDataforTIMP: async (authKey, assetIdarray) => {
		try {
			// console.log(assetIdarray);
			const res = await Axios.post(`${config.SPOTWS_URL}/assets/data/3/current`, assetIdarray, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	readDataSPOT: async (authKey, assetIdArr) => {
		// console.log("authKey",authKey);
		// console.log("assetIdArr",assetIdArr);
		try {
			const res = await Axios.post(`${config.SPOTWS_URL}/assets/data/22/current`, assetIdArr, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			for (let i = 0; i < res.data.length; i++) {
				const data = res.data[i];
				if (!data || !data.utcEpoch) {
					let utcTimestamp = Date.now();
					const resetData = {
						"id": assetIdArr[i],
						"setup_status": 0,
						"cal_status": 0,
						"calibration_status": 0,
						"instalation_status": 0,
						"utcEpoch": utcTimestamp,
						"test_status": [
							0,
							0,
							0,
							0,
							0
						],
						"people_count": [
							0,
							0,
							0,
							0
						]
					}
					res.data[i] = resetData;
				}
			}
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	readDataSPOTNew: async (authKey, assetId) => {
		let myHeaders = new Headers();
		myHeaders.append("x-location-token", await AuthService.fetchAuthKey());
		myHeaders.append("authorization", process.env.REACT_APP_AUTHORIZATION_KEY);
		myHeaders.append("Content-Type", "application/json");

		let raw = JSON.stringify([
			assetId
		]);

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};
		const response = await fetch(`${config.SPOTWS_URL}/assets/data/22/current`, requestOptions);
		const result = await response.json();
		return result[0];

		// if (!data || !data.utcEpoch) {
		// 	let utcTimestamp = Date.now();
		// 	const resetData = {
		// 		"id": assetId,
		// 		"setup_status": 0,
		// 		"cal_status": 0,
		// 		"calibration_status": 0,
		// 		"instalation_status": 0,
		// 		"utcEpoch": utcTimestamp,
		// 		"test_status": [
		// 			0,
		// 			0,
		// 			0,
		// 			0,
		// 			0
		// 		],
		// 		"people_count": [
		// 			0,
		// 			0,
		// 			0,
		// 			0
		// 		]
		// 	}
		// 	res.data = resetData;
		// }
	},

	readTimPDataSPOT: async (authKey, assetIdArr) => {
		// console.log("assetIdArr",assetIdArr);
		try {
			const res = await Axios.post(`${config.SPOTWS_URL}/assets/data/29/current`, assetIdArr, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	readTIMData: async (agentIdArr) => {
		try {
			const res = await Axios.get(`${config.ELECTRIC_URL}/${agentIdArr}/TIM/current`, {
				headers: {
					"Authorization": await AuthService.fetchAuthKey(),
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data)
		}
	},

	sendCalibrationCommand: async (agentIdArr) => {
		try {
			const res = await Axios.get(`${config.ELECTRIC_URL}/${agentIdArr}/TIM/calibration`, {
				headers: {
					"Authorization": await AuthService.fetchAuthKey(),
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	increaseFrameRate: async (agentIdArr) => {
		try {
			const res = await Axios.get(`${config.ELECTRIC_URL}/${agentIdArr}/TIM/test`, {
				headers: {
					"Authorization": await AuthService.fetchAuthKey(),
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	// increaseBatTimFrameRate: async (agentIdArr) =>{
	// 	try{
	// 		const res = await Axios.get(`https://agent.electricimp.com/${agentIdArr}/BTIM/testMode`,{
	// 			headers: {
	// 				"Authorization": AuthService.authenticationKey(),
	// 			},
	// 		},
	// 		);
	// 		return res.data;
	// 	}catch(err){
	// 		throw (err.response.data.error)
	// 	}
	// },

	increaseBatTimFrameRate: async (testModeFlag, agentId) => {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		let raw = JSON.stringify({
			"utcTime": 0,
			"testModeFlag": testModeFlag,
			"testModeOnTime": 10
		});
		let url = `https://agent.electricimp.com/${agentId}/BTIM/testMode`;
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		try {
			const response = await fetch(url, requestOptions);
			return response.status
		}
		catch (error) {
			console.log('Error:', error);
		}
	},

	defaultFrameRate: async (agentIdArr) => {
		// console.log(agentIdArr);
		try {
			const res = await Axios.get(`${config.ELECTRIC_URL}/${agentIdArr}/TIM/defultFrameRate`, {
				headers: {
					"Authorization": AuthService.authenticationKey(),
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	writeDataToSpot: async (dataToWrite) => {
		try {
			const res = await Axios.post(`${config.SPOTWS_URL}/ingest/22?asyncCall=false`, dataToWrite, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getAssetId: async (spaceId) => {
		try {
			const res = await Axios.get(`${config.PRODWS_URL}/api/devices/asset/fetch?identifier=space&id=${spaceId}`, {
				headers: {
					"licenseKey": process.env.REACT_APP_LICENSE_KEY,
				}
			});
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getCountFromSpot: async (assetIdArr) => {
		try {
			const res = await Axios.post(`${config.SPOTWS_URL}/assets/data/24/current`, assetIdArr, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	uploadFileTos3: async (file) => {
		var data = new FormData();
		data.append('image', file);
		try {
			const res = await Axios.post(`${config.UPLOAD_URL}/api/upload`, data, {
				headers: {
					"x-auth-key": await AuthService.fetchAuthKey(),
					'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				},
			},
			);
			return res.data;
		} catch (err) {
			//console.error("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^", err);
			throw (err.response.data.error)
		}
	},

	// readDataTIMpSPOT: async (authKey, assetIdArr) => {

	// 	let data = JSON.stringify([
	// 		assetIdArr
	// 	]);

	// 	let config = {
	// 		method: 'post',
	// 		maxBodyLength: Infinity,
	// 		url: `${config.SPOTWS_URL}/assets/data/29/current`,
	// 		headers: {
	// 			'x-location-token': authKey,
	// 			'authorization': process.env.REACT_APP_AUTHORIZATION_KEY,
	// 			'Content-Type': 'application/json'
	// 		},
	// 		data: data
	// 	};
	// 	try {
	// 		const result = await Axios.request(config);
	// 		return result.data;
	// 	} catch (err) {
	// 		console.error(err);
	// 		throw (err.response.data.error)
	// 	}

	// },


	// readDataTIMpSPOT: async (authKey, assetIdArr) => {
	// 	console.log("--------------------->",assetIdArr);
	// 	try {
	// 		const res = await Axios.post(`${config.SPOTWS_URL}/assets/data/29/current`, JSON.stringify([assetIdArr]), {
	// 			headers: {
	// 				"x-location-token": authKey,
	// 				"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
	// 				"Content-Type": "application/json"
	// 			},
	// 		},
	// 		);
	// 		return res.data;
	// 	} catch (err) {
	// 		console.error(err);
	// 		throw (err.response.data.error)
	// 	}
	// },


	writeDataToTIMpSpot: async (dataToWrite) => {
		try {
			const res = await Axios.post(`${config.SPOTWS_URL}/ingest/29?asyncCall=false`, dataToWrite, {
				headers: {
					"x-location-token": await AuthService.fetchAuthKey(),
					"authorization": process.env.REACT_APP_AUTHORIZATION_KEY,
					"Content-Type": "application/json"
				},
			},
			);
			return res.data;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

}
export default AuthService;